<template>
  <div class="register">
    <h3>Registrasi</h3>
    <p>silahkan lengkapi data berikut</p>

    <a-form :form="form" @submit="handleSubmit">
      <a-form-item>
        <a-input
          autocomplete="off"
          v-decorator="[
            'nama',
            {
              rules: [
                {
                  required: true,
                  message: 'Silahkan isi field ini',
                },
              ],
            },
          ]"
          type="text"
          placeholder="Nama"
        />
      </a-form-item>

      <a-form-item>
        <a-input
          autocomplete="off"
          v-decorator="[
            'email',
            {
              rules: [
                {
                  type: 'email',
                  required: true,
                  message: 'Silahkan isi field ini',
                },
              ],
            },
          ]"
          type="text"
          placeholder="Email"
        />
      </a-form-item>

      <a-form-item>
        <a-input
          autocomplete="off"
          v-decorator="['telp']"
          type="text"
          placeholder="Nomer Hp (Optional)"
        />
      </a-form-item>

      <a-button
        type="primary"
        html-type="handleSubmit"
        :loading="isLoading"
        block
      >
        Registrasi <a-icon type="play-circle" />
      </a-button>
    </a-form>
    <div style="margin-top: 20px">
      <a-button type="primary-dua" block @click="login">
        Masuk <a-icon type="play-circle"
      /></a-button>
    </div>

    <div class="imagesregist">
      <img :src="require('@/assets/img/register.png')" />
    </div>

    <a-modal
      v-model="show"
      :width="340"
      :footer="null"
      :closable="false"
      :maskClosable="false"
    >
      <div class="modal-custom">
        Dengan melakukan registrasi di booth ini, Anda menyetujui penggunaan
        data pribadi Anda berupa nama, email, dan telpon, untuk kami simpan dan
        gunakan selama event berlangsung
      </div>
      <a-button type="primary" @click="lanjutkan" block
        >Lanjutkan <a-icon type="play-circle"
      /></a-button>
    </a-modal>
  </div>
</template>

<script>
export default {
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "FormLogin" });
  },
  data() {
    return {
      show: true,
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    },
  },
  methods: {
    login() {
      this.$router.push({ name: "login" });
    },
    lanjutkan() {
      this.show = false;
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$store
            .dispatch("Register", {
              ...values,
            })
            .then((response) => {
              console.log(response);
              this.$notification.success({
                message: response.message,
              });
              this.$router.push({ name: "login" });
            });
        }
      });
    },
  },
};
</script>
